/*Color Pallet
Nav Links => #3c5258
Logo => #233237

Gold Hover: #D4AF37
- 
*/

html,
body {
  margin: 0;
  font-family: "Vollkorn", serif !important;
  height: 100%;
  color: white !important;
  width: 100%;
  background-color: black !important;
}

.white-background {
  background-color: white;
}

.black-background {
  background-color: black !important;
}

.color-nav {
  transition: background-color 0.5s ease;
}

.color-nav.sticky {
  background-color: #f8f9fa;
}

.carousel-indicators {
  top: 22rem !important;
}

.contact-section {
  width: 50%;
}

.booking-container {
  min-height: 100vh !important;
}

.flex-container {
  display: flex !important;
}

.flex-child {
  flex: 1;
}

.flex-child:first-child {
  margin-left: 100px !important;
}

.Matt {
  border-radius: 50% !important;
}

@font-face {
  font-family: "sisteron";
  src: url("./fonts/sisteron/TTF/Sisteron-Regular.ttf");
}

.color-nav {
  color: #f5e6bb !important;
}

.contact-text {
  color: #233237;
  font-family: "Vollkorn", serif;
}
.contact-text:hover {
  color: #3c5258;
}

.nav-hover {
  color: white;
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 4px;
  text-align: center;
  position: relative;
  margin: 0 10px;
}

.logo-main {
  color: white;
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: 6px;
  text-align: center;
}

.logo-main:hover {
  text-decoration: none;
  color: white;
}

.nav-hover:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 300px;
  height: 0.1em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.nav-hover:hover::after {
  transform: scaleX(1);
  transform-origin: right left;
}

.nav-hover:hover {
  color: goldenrod;
}

.nav-logo {
  font-weight: 900 !important;
  font-family: "sisteron";
  font-size: 24px !important;
  letter-spacing: 0.2em;
  color: #233237 !important;
  display: flex;
}

.nav-logo:hover {
  color: #3c5258 !important;
}
.booking-banner {
  font-family: highgate, sans-serif;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.booking-btn-container {
  margin-top: 40px;
}

.booking-btn {
  background-color: #00504b !important;
  color: white !important;
  border: none !important;
  transition: background-color 0.5s !important;

  letter-spacing: 2px;
  font-family: "Poppins" !important;
}

.booking-btn:hover {
  background-color: #207a73 !important;
}

.booking-btn:active {
  opacity: 90%;
}

.btn-reduce {
  border: none !important;
  background-color: #984b43 !important;
  margin-top: 2rem;
  width: 35%;
}

.booking-cards {
  margin-top: 3em !important;
}

.book-btn {
  text-decoration: none !important;
  color: #233237 !important;
  font-family: "Poppins";
  font-weight: 400 !important;
}

.shows_container_one {
  display: flex;
  flex-direction: column; /* Ensures the h3 and ul are in a column layout */
  align-items: center; /* Centers the ul horizontally */
}

.shows_container_two {
  display: flex;
  flex-direction: column; /* Ensures the h3 and ul are in a column layout */
  align-items: center; /* Centers the ul horizontally */
}

.shows_container_three {
  display: flex;
  flex-direction: column; /* Ensures the h3 and ul are in a column layout */
  align-items: center; /* Centers the ul horizontally */
}

.shows_col {
  display: table-cell;
  vertical-align: middle;
}

.shows_col-date {
  width: 140px;
  padding-right: 10px;
}

.shows_venue {
  display: block;
  margin-bottom: 2px;
}

.shows_location {
  display: block;
  margin-bottom: 2px;
}

.shows_location_link {
  color: white;
}

.shows_time {
  display: block;
  margin-bottom: 2px;
}

.date-location {
  display: inline-flex;
}

.month {
  text-align: center;
}

.venue {
  display: flex;
  justify-content: end;
}

.date {
  display: flex;
  justify-content: start;
}

.location {
  display: flex;
  justify-content: end;
}

.showtime {
  display: flex;
  justify-content: end;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f5f5f5;
}

.App-link {
  color: #61dafb;
}

.fb-icon {
  color: #233237;
  margin: 1rem;
}

.insta-icon {
  color: #233237;
  margin: 1rem;
}

.insta-icon:hover {
  color: #3c5258;
}
.fb-icon:hover {
  color: #3c5258;
}

.fb-icon-foot {
  color: #f5f5f5;
  padding: 2px;
}

.insta-icon-foot {
  color: #f5f5f5;
  padding: 2px;
}

.fb-icon-foot:hover {
  color: #3c5258 !important;
  cursor: pointer;
}

.insta-icon-foot:hover {
  color: #3c5258 !important;
  cursor: pointer;
}
.video-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important; /* Center vertically */
  overflow: hidden;
  height: 30em;
}

.video {
  /* You can keep these styles if needed */
  display: flex;
  align-self: center !important;
  border-radius: 0%;
  border: none !important;
}

#hero-img {
  margin-bottom: 10px;
}

.shows-video-container {
  padding-top: 1em;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  justify-content: center;
}
.shows-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.recent-shows-header {
  margin-top: 80px;
  margin-bottom: 40px;
}
.show-one,
.show-two,
.show-three {
  margin-bottom: 80px;
}

#upcoming-shows-section {
}
.drop-menu {
  background-color: transparent !important;
  border: none !important;
}

.dropdown-item:hover {
  background-color: #233237 !important;
  color: #f3eddc !important;
}
iframe {
  border-radius: 20px;
  border: solid #282c34 !important;
}
.if-spinner {
  background-image: url("/public/images/Spinner2.gif");
  background-repeat: no-repeat;
  background-position: 50% 25%;
}

.footer {
  width: 100%;
  bottom: 0;
  background-color: black;
  color: white;
  font-size: 12px;
  line-height: 1.8;
}

.contact-footer {
  text-decoration: none;
  color: #f5f5f5;
  text-transform: uppercase;
}

.footer-text {
  text-decoration: none;
  color: #f5f5f5;
  text-transform: uppercase;
}

.rights-text {
  color: #f5f5f5;
  text-transform: uppercase;
}

.footer-text:hover {
  color: #3c5258 !important;
}

.card-bg {
  background: none !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  border-radius: 10px !important;
  border: none !important;
}
.card-img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
  border-radius: 10px 10px 0 0 !important;
  border: none !important;
}

.card-body {
  background-color: #984b43;
  border-radius: 0 0 10px 10px !important;
  border: none !important;
}

.card-bg:hover {
  transform: scale(1.05) !important;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-radius: 10px !important;
  border: none !important;
}

.card-titles {
  font-family: highgate, sans-serif;
  font-weight: 400;
  color: white;
}

ul {
  list-style-type: none !important;
}

#shows-section {
  margin-top: 80px;
  background-color: black;
}

.upcoming-shows {
  border: solid white;
  width: 100%;
}

.flex-container {
  display: flex;
}

.flex-item {
  flex: 1;
  /* Add additional styling as needed */
}

.shows_list-item {
  display: table;
}

.shows-banner {
  font-family: poppins, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  margin-top: 16px;
  margin-left: 8px;
}
.shows-sub-banner {
  font-family: poppins, sans-serif;
  font-size: small;
  text-transform: uppercase;
  color: white;
  margin-left: 12px;
}
.center-list {
  font-family: "Vollkorn", serif;
  flex-wrap: wrap;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.show-text {
  color: #556c72 !important;
  font-size: 18px !important;
}
.center-list:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 300px;
  height: 0.1em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.center-list:hover::after {
  transform: scaleX(1);
  transform-origin: right left;
}

.center-list:hover {
  color: #233237 !important;
}

.contact-form {
  border: none !important;
}
.footer-register {
  border: none !important;
}

.banner-text div {
  text-transform: uppercase;
  font-size: 55px;
  color: #233237;
  letter-spacing: 4.8px;
  font-weight: 800;
}

@media screen and (max-width: 495px) {
  .banner-text div {
    font-size: 10vw;
  }
}
.banner-text {
  margin-top: 80px;
  margin-bottom: 40px;
}
